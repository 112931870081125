.upperContainer {
    background-color: #004070;
    color: white;
  padding: 1rem;
    padding-top: 4rem;
    padding-bottom: 2rem;
    justify-items: center;  
}

.bottomContainer {
    background-color: #EFEFEF;
    align-items: center;
    color: #004070;
    font-weight: bold;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.icons {
    color: white;
    height: 28px;
    width: 28px;
}

.footerLogo {
    float: right;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
    .upperContainer {
        display: grid;
    }
}

/* -- Tablet portrait */
@media (min-width: 768px) {
    .bottomContainer {
        padding-left: 10%;
        padding-right: 10%;
    }

    .upperContainer {
        padding-left: 10%;
        padding-right: 10%;
    }
}

/* -- Tablet landscape */
@media (min-width: 992px) {
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
    .bottomContainer {
        padding-left: 15%;
        padding-right: 15%;
    }

    .upperContainer {
        padding-left: 15%;
        padding-right: 15%;
    }
}

/* -- FullHD */
@media (min-width: 1921px) {
}
