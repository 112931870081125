.container {
    margin-top: 3rem;
    height: 100%;
    min-height: 550px;
    padding: 2rem 0;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.headerSection {
    background: url("../assets/images/main_header_background.png");
    text-align: center;
    display: inherit;
    flex-direction: column;
    justify-content: center;
    align-items: inherit;
    width: 100%;
    font-family: var(--font-main);
}

.header_Title {
    margin-top: 5rem !important;
    color: var(--color-main);
}

.header_DescSecondary {
    width: 80%;
    font-size: medium;
    margin-bottom: 5rem !important;
}

.clicker {
    font-size: 22px;
    align-self: center;
    align-items: center;
    background-image: linear-gradient(144deg, #1C9EFF 0%, #004070 30%, #004070 55%, #1C9EFF);
    border: 0;
    margin: 3rem 0;
    border-radius: 8px;
    box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
    box-sizing: border-box;
    color: #FFFFFF;
    display: flex;
    font-family: Phantomsans, sans-serif;
    justify-content: center;
    line-height: 1em;
    max-width: 100%;
    min-width: 140px;
    padding: 3px;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    cursor: pointer;
}

.listItem {
    margin-top: 1rem;
}

.clicker:active,
.clicker:hover {
    outline: 0;
}

.clicker span {
    background-color: rgb(5, 6, 45);
    padding: 16px 24px;
    border-radius: 6px;
    width: 100%;
    height: 100%;
    transition: 300ms;
}

.clicker:hover span {
    background: none;
}

.description {
    display: inherit;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    font-size: 0.85rem;
    width: 95%;
    z-index: 2;
    font-family: var(--font-main);
}

.description a {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.description p {
    position: relative;
    text-align: left;
    line-height: 2rem;
    margin: 0;

    /* background-color: rgba(var(--callout-rgb), 0.5);
    border: 1px solid rgba(var(--callout-border-rgb), 0.3);
    border-radius: var(--border-radius); */
}

.descriptionContainer {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background-color: rgba(var(--callout-rgb), 0.5);
    border: 1px solid rgba(var(--callout-border-rgb), 0.3);
    border-radius: var(--border-radius);
}

.descSummary {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 1rem;
}

.listItem {
    margin-top: 1rem;
}

.listItem:nth-child(1) {
    margin: 0;
}

.descriptionTitle {
    text-align: left;
    margin-top: 3rem;
}


/* -- Mobile landscape */
@media (min-width: 480px) {
}

/* -- Tablet portrait */
@media (min-width: 768px) {
    .clicker {
        font-size: 24px;
        min-width: 196px;
    }

    .descSummary {
        flex-direction: row;
        align-items: center;
    }
}

/* -- Tablet landscape */
@media (min-width: 992px) {
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
    .header_DescSecondary {
        width: 60%;
    }

    .description {
        width: 60%;
    }
}

/* -- FullHD */
@media (min-width: 1921px) {
}
