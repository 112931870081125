.container {
    gap: 1rem;
    position: relative;
    padding: 5rem 1rem 1rem 1rem;
}

.download {
    color: var(--color-light)
}

p{
    font-family: var(--font-main);
}


/* -- Mobile landscape */
@media (min-width: 480px) {
    .container {
        padding: 5rem 2rem 8rem;
        width: calc(100% - 4rem);
    }
}

/* -- Tablet portrait */
@media (min-width: 768px) {
}

/* -- Tablet landscape */
@media (min-width: 992px) {
    .container {
        --content_padding: calc(100vw - 60rem);

        padding: 5rem calc(var(--content_padding) / 2) 5rem;
        width: calc(100% - var(--content_padding));
    }
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
    .container {
        --content_padding: calc(100vw - 70rem);

        padding: 5rem calc(var(--content_padding) / 2) 5rem;
        width: calc(100% - var(--content_padding));
    }
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
    .container {
        --content_padding: calc(100vw - 78rem);

        padding: 5rem calc(var(--content_padding) / 2) 5rem;
        width: calc(100% - var(--content_padding));
    }
}

/* -- FullHD */
@media (min-width: 1921px) {
    .container {
        --content_padding: calc(100vw - 90rem);
        padding: 5rem 17% 5rem;
        width: 70%;
    }
}
    