body {
    --primary: #00408c;
    --background: #ffffff;
    --background-dim: #f3f3f3;
    --background-dim-light: #f9f9f9;
    --primary-foreground: #ffffff;
    --foreground: #161616;
    --base-unit: 8px;
}

#injectCustomComplete {
    background-color: var(--primary);
}

#injectCustomComplete:hover {
    background-color: var(--background-dim);
}