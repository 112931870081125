.main {
    height: 5rem;
    background-color: #004070;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 10000;
}

.drawerContainer {
    display: flex;
    justify-content: space-between;
    padding-top: 0.8rem;
    padding-left: 5%;
    padding-right: 5%;
}

.buttonStyle {
    color: white !important;
    margin-left: 0.5rem;
}
/* src/styles/NavBar.module.css */
.drawerList {
    display: flex;
    flex-direction: column;
    z-index: 1000; /* Adjust as needed */
}
.DrawerButtonStyle {
    color: black !important;
    margin-left: 0.5rem;
}
.HighlightedMenuItem{
    color: #ff4b4b !important;
}
.listContainer {
    margin-top: 0.3rem;
}

.largeIcon {
    width: 40px !important;
    height: 40px !important;
    color: white !important;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
}

/* -- Tablet portrait */
@media (min-width: 768px) {
    .drawerContainer {
        padding-left: 10%;
        padding-right: 10%;
    }
}

/* -- Tablet landscape */
@media (min-width: 992px) {
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
    .drawerContainer {
        padding-left: 15%;
        padding-right: 15%;
    }
}

/* -- FullHD */
@media (min-width: 1921px) {
}
