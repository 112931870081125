.main {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: center;
    padding: 5rem 0;
    min-height: 100vh;
}

[class*="Section"] {
    display: inherit;
    flex-direction: column;
    justify-content: center;
    align-items: inherit;
    width: 100%;
    font-family: var(--font-main);
}

.headerSection {
    background: url("../assets/images/main_header_background.png");
    text-align: center;
}

.headerSection2 {
    margin-top: 2rem;
    background: transparent;
    text-align: center;
}

[class*="header_"] {
    margin: 1rem;
}

.header_Title {
    margin-top: 5rem !important;
    color: var(--color-main);
}

.header_DescSecondary {
    font-size: small;
    margin-bottom: 5rem !important;
}

.graphSection {
    flex-direction: row;
    flex-wrap: wrap;
}

.graphSection2 {
    flex-direction: column;
}

.graphDescription {
    width: 95%;
    text-align: center;
}


.graph_1 {
    background: url("../assets/images/graph_1.png");
    background-size: 100%;
    width: 350px;
    height: 350px;
    -webkit-filter: drop-shadow(0px 20px 9px grey);
    filter: drop-shadow(0px 20px 9px grey);

    transition: all 0.3s;
}

.graph_2 {
    width: 350px;
    height: 350px;
    background: url("../assets/images/graph_2.png");
    -webkit-filter: drop-shadow(0px 20px 9px grey);
    filter: drop-shadow(0px 20px 9px grey);
    background-size: 100%;
}

.graph_3 {
    background: url("../assets/images/graph_3.png");
    background-size: cover;
    width: 40% !important;
    height: 320px !important;
}

.description {
    display: inherit;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    font-size: 0.85rem;
    width: 95%;
    z-index: 2;
    font-family: var(--font-main);
}

.description a {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.description p {
    position: relative;
    text-align: left;
    line-height: 2rem;
    margin: 0;

    /* background-color: rgba(var(--callout-rgb), 0.5);
    border: 1px solid rgba(var(--callout-border-rgb), 0.3);
    border-radius: var(--border-radius); */
}

.descriptionContainer {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background-color: rgba(var(--callout-rgb), 0.5);
    border: 1px solid rgba(var(--callout-border-rgb), 0.3);
    border-radius: var(--border-radius);
}

.descSummary {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 1rem;
}

.listItem {
    margin-top: 1rem;
}

.listItem:nth-child(1) {
    margin: 0;
}

.descriptionTitle {
    text-align: left;
    margin-top: 3rem;
}

.linkedIcon {
    font-size: 60px !important;
    color: #0070c5;
}

.faceIcon {
    font-size: 60px !important;
    color: rgb(20, 20, 199);
}

.youtubeIcon {
    font-size: 60px !important;
    color: red;
}

.expertSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.expert {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
    text-align: left !important;
    color: var(--color-main) !important;
    margin-top: 2rem;
    margin-bottom: auto;
}


@media (hover: hover) and (pointer: fine) {

}

/* Mobile */
@media (min-width: 480px) {
}

/* -- Tablet portrait */
@media (min-width: 768px) {
    .descSummary {
        flex-direction: row;
        align-items: center;
    }

    .expertSection {
        flex-direction: row;
    }

    .descSummary a {
        margin-right: 4rem;
    }
}

/* -- Tablet landscape */
@media (min-width: 992px) {
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
    .description {
        width: 60%;
    }
}

/* -- Laptop (HD+) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}