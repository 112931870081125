.container{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150vh;
}

.paper{
    padding: 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.icon{
    font-size: 100px !important;
    color: rgb(17, 143, 17);
}

.text{
    margin-top: 2rem;
}