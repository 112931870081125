.main {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: center;
    padding: 5rem 0;
    min-height: 100vh;
    font-family: var(--font-main);
}

[class*="Section"] {
    display: inherit;
    flex-direction: column;
    justify-content: center;
    align-items: inherit;
    width: 100%;
    font-family: var(--font-main);
}

.headerSection {
    background: url("../assets/images/main_header_background.png");
    text-align: center;
    font-family: var(--font-main);
}

.headerSection2 {
    margin-top: 2rem;
    background: transparent;
    text-align: center;
    font-family: var(--font-main);
}

[class*="header_"] {
    margin: 1rem;
    font-family: var(--font-main);
}

.header_Title {
    margin-top: 5rem !important;
    color: var(--color-main);
    font-family: var(--font-main);
}


.header_DescSecondary {
    font-size: small;
    margin-bottom: 5rem !important;
    font-family: var(--font-main);
}

.graphSection {
    flex-direction: row !important;
    flex-wrap: wrap;
    font-family: var(--font-main);
}

.graphSection2 {
    flex-direction: column;
    font-family: var(--font-main);
}

.graphDescription {
    width: 95%;
    text-align: center;
    font-family: var(--font-main);
}


.graph_1 {
    background: url("../assets/images/graph_1.png");
    background-size: 100%;
    width: 350px;
    height: 350px;
    -webkit-filter: drop-shadow(0px 20px 9px grey);
    filter: drop-shadow(0px 20px 9px grey);
    transition: all 0.3s;
    font-family: var(--font-main);
}

.graph_2 {
    width: 350px;
    height: 350px;
    background: url("../assets/images/graph_2.png");
    -webkit-filter: drop-shadow(0px 20px 9px grey);
    filter: drop-shadow(0px 20px 9px grey);
    background-size: 100%;
    font-family: var(--font-main);
}

.cardContainer {
    width: 95%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    font-family: var(--font-main);
}

.card {
    width: 20%;
    padding: 2rem 1rem 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    min-width: 270px;
    min-height: 250px;
    font-family: var(--font-main);
}

.cardTitle {
    position: relative;
    background-color: var(--color-main);
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: whitesmoke;
    width: 100%;
    border-radius: 1rem 1rem 0 0;
    box-shadow: 0px 10px 20px grey;
    font-family: var(--font-main);
}

.cardDesc {
    background-color: rgb(225, 225, 225);
    height: 100%;
    margin-top: auto;
    border-radius: 0 0 1rem 1rem;
    padding: 1rem;
    box-shadow: 0px 10px 20px grey;
    font-family: var(--font-main);
}

.icon {
    position: absolute;
    left: 1rem;
    font-family: var(--font-main);
}

.description {
    display: inherit;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    font-size: 0.85rem;
    width: 95%;
    z-index: 2;
    font-family: var(--font-main);
}

.description a {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    font-family: var(--font-main);
}

.description p {
    position: relative;
    text-align: left;
    line-height: 2rem;
    margin: 0;
    font-family: var(--font-main);
    /* background-color: rgba(var(--callout-rgb), 0.5);
    border: 1px solid rgba(var(--callout-border-rgb), 0.3);
    border-radius: var(--border-radius); */
}

.descriptionContainer {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background-color: rgba(var(--callout-rgb), 0.5);
    border: 1px solid rgba(var(--callout-border-rgb), 0.3);
    border-radius: var(--border-radius);
    font-family: var(--font-main);
}

.descSummary {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 1rem;
    font-family: var(--font-main);
}

.listItem {
    margin-top: 1rem;
    font-family: var(--font-main);
}

.listItem:nth-child(1) {
    margin: 0;
    font-family: var(--font-main);
}

.descriptionTitle {
    text-align: left;
    margin-top: 3rem;
    font-family: var(--font-main);
}

.clicker {
    font-size: 22px;
    align-self: center;
    align-items: center;
    background-image: linear-gradient(144deg, #1C9EFF 0%, #004070 30%, #004070 55%, #1C9EFF);
    border: 0;
    margin: 3rem 0;
    border-radius: 8px;
    box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
    box-sizing: border-box;
    color: #FFFFFF;
    display: flex;
    font-family: Phantomsans, sans-serif;
    justify-content: center;
    line-height: 1em;
    max-width: 100%;
    min-width: 140px;
    padding: 3px;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    cursor: pointer;
    font-family: var(--font-main);
}


.clicker:active,
.clicker:hover {
    outline: 0;
}

.clicker span {
    background-color: rgb(5, 6, 45);
    padding: 16px 24px;
    border-radius: 6px;
    width: 100%;
    height: 100%;
    transition: 300ms;
    font-family: var(--font-main);
}

.clicker:hover span {
    background: none;
}


/* Mobile */
@media (min-width: 480px) {
}

/* -- Tablet portrait */
@media (min-width: 768px) {
    .graph_1:hover {
        transform: scale(1.3);
    }

    .descSummary {
        flex-direction: row;
        align-items: center;
    }
}

/* -- Tablet landscape */
@media (min-width: 992px) {
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
    .graphDescription {
        width: 60%;
    }

    [class*="graph_"] {
        width: 500px;
        height: 500px;
    }

    .description {
        width: 60%;
    }

    .cardContainer {
        width: 70%;
        padding: 0;
    }

    .card {
        max-width: 20%;
    }
}

/* -- Laptop (HD+) */
@media (min-width: 1600px) {
    [class*="graph_"] {
        width: 600px;
        height: 600px;
    }

    .cardContainer {
        width: 60%;
        padding: 0;
    }
}

/* -- FullHD */
@media (min-width: 1921px) {
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes move {
    from {
        transform: translateX(-1000px);
    }
    to {
        transform: translateX(0);
    }
}
