.modalStyling {
    background-color: rgb(0, 64, 140) !important;
    color: white !important;
}

.modalStyling:hover {
    background-color: rgba(0, 64, 140, 0.6) !important;
    color: white !important;
}

.disabledButton {
    color: lightgray;
}